import './fabrication_workflow.scss';

// External Libs
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Axios from 'axios';
import React, { Component } from 'react';
import Select from 'react-select';

// Internal Components
import SessionExpire from '../../../../../common/session_expire';
import { handleHttpRequestError } from '../../../../../common/error';
import { convertDateWithFormat, convertDate, setTokenHeader, removeIreqIdInitialNumber, configLightBox } from '../../../../../common/functions';
import Modal from '../../../../../components/modal/modal';
import { UserPermissionsContext } from '../../../../../context/user_permission';
import ThumbnailURLInterface from '../../../../../components/thumbnail/thumbnail_url_interface';

// Contants
import {
  SUCCESS,
  FAILURE,
  FABRICATION_STATUSES,
  COURSE_OF_ACTION,
  PROCESS_STEP,
  LOWER,
  UPPER,
  LOCATION_OPTION,
  LOCATION_NUMBERS,
  DEFAULT_BREAK_LOCATION,
  COURSE_OF_ACTION_ONLY_REDESIGN,
  WIRE_FABRICATION_LOG_STATUSES,
  EDIT_STATUSES,
} from './constants';
import {
  WS_WIRE_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_REINITIATED_STATUS,
  WS_COMPLETE_FABRICATION_STATUS,
  WS_REDESIGN_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_RESOLVED_STATUS,
  WS_CUSTOM_WO_STATUS,
} from '../constants';
import { userHasPermission } from '../../../../../common/permission';

class FabricationWorkflow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      refresh: false,
      action_modal: '',
      modal_in_progress_text: '',
      modal_in_progress: '',
      permitted_users: [],
      modal: null,
      upload_modal: null,
      remove_upload: null,
      refreshThumbnail: 0,
      prevRevWires: [],
      wireErrors: [],
    };
    this.displayWireItems = this.displayWireItems.bind(this);
    this.onModalConfirm = this.onModalConfirm.bind(this);
    this.fetchUpdateIRFile = this.fetchUpdateIRFile.bind(this);
  }

  componentDidMount() {
    this.updateItems();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.updateItems();
    }
    if (prevProps.ws_data.ir_files != this.props.ws_data.ir_files) {
      this.setState({ refreshThumbnail: this.state.refreshThumbnail + 1 });
    }
  }

  /**
   * Updates an item by modifying its status and break locations.
   *
   * @param {object} item - The item to be updated.
   * @returns {object} Returns the updated item.
   */
  updateItem(item) {
    const updatedItem = { ...item };
    updatedItem.status = updatedItem.status ? updatedItem.status : SUCCESS;

    const breakLocations = updatedItem.break_locations ? JSON.parse(updatedItem.break_locations) : [{ ...DEFAULT_BREAK_LOCATION }];

    updatedItem.break_locations = breakLocations.map((breakLocation) => {
      const updatedBreakLocation = { ...breakLocation };
      updatedBreakLocation.uuid = uuidv4();
      return updatedBreakLocation;
    });
    return updatedItem;
  }

  /**
   * Updates all items in the component state.
   * Filters the items based on certain conditions and applies the updateItem function to each item.
   * Updates the component state with the updated items.
   */
  updateItems() {
    const updatedItems = this.props.items
      .filter((item) => item.current_rev && item.group === 'smartwire')
      .map((item) => {
        return this.updateItem(item);
      });

    if (updatedItems.length > 0) {
      const currentRev = updatedItems[0].wire_rev;
      const prevWires = this.getPrevRevWires(currentRev);
      this.setState({ prevRevWires: prevWires });
    }

    this.setState({ items: updatedItems });
  }

  getPrevRevWires(currentRev) {
    const smartwires = this.props.items.filter((item) => item.group === 'smartwire');

    if (currentRev !== 'A') {
      const sortedItems = smartwires.sort((a, b) => a.wire_rev.localeCompare(b.wire_rev));
      const currentIndex = sortedItems.findIndex((item) => item.wire_rev === currentRev);
      if (currentIndex > 0) {
        const prevRev = sortedItems[currentIndex - 1].wire_rev;
        return smartwires.filter((item) => item.wire_rev === prevRev);
      }
    }
    return smartwires.filter((item) => item.current_rev);
  }

  /**
   * Handles the update of an item's field by making a PATCH request to the server.
   *
   * @param {object} item - The item to update.
   * @param {object} fields - The fields to be updated.
   */
  updateItemFieldChanged(item, fields) {
    setTokenHeader();
    const that = this;
    const data = { ireq_wire_selection_id: item.id, fields };
    Axios.patch(`/apiv3/ireqautomationstatus/${this.props.ireq_id}/ws/update_field`, { ...data }).catch(function (err) {
      handleHttpRequestError(err, that);

      if (err && err.response && err.response.status === 409) {
        that.setState({
          action_modal: '',
          modal_in_progress: false,
        });
      }
    });
  }

  /**
   * Handles the change of an item's status.
   *
   * @param {object} selectedOption - The selected option representing the new status.
   * @param {number} index - The index of the item in the items array.
   */
  handleStatusChange = (selectedOption, index) => {
    const items = [...this.state.items];
    if (selectedOption.value === items[index].status) return;

    items[index] = { ...items[index], status: selectedOption.value };
    const data_to_update = { status: selectedOption.value };
    this.setState({ items });
    this.updateItemFieldChanged(items[index], data_to_update);
  };

  /**
   * Handles the change of an item's course of action.
   *
   * @param {object} selectedOption - The selected option representing the new course of action.
   * @param {number} index - The index of the item in the items array.
   */
  handleCourseOfActionChange = (selectedOption, index) => {
    const items = [...this.state.items];
    items[index] = { ...items[index], course_of_action: selectedOption.value };
    this.setState({ items });
    this.updateItemFieldChanged(items[index], { course_of_action: selectedOption.value });
  };

  /**
   * Removes the UUID property from an item's break locations.
   *
   * @param {number} item_idx - The index of the item in the items array.
   * @returns {Array} Returns an array of break locations without the UUID property.
   */
  removeUuid = (item_idx) => {
    return this.state.items[item_idx].break_locations.map((item) => {
      const { uuid, ...break_location } = item;
      return break_location;
    });
  };

  /**
   * Handles the change of a process step for a break location.
   *
   * @param {object} selectedOption - The selected option representing the new process step.
   * @param {number} item_idx - The index of the item in the items array.
   * @param {number} location_idx - The index of the break location within the item.
   */
  handleProcessStepChange = (selectedOption, item_idx, location_idx) => {
    this.setState(
      (prevState) => {
        const items = [...prevState.items];
        items[item_idx].break_locations[location_idx] = {
          ...items[item_idx].break_locations[location_idx],
          process_step: selectedOption.value,
        };
        return { items };
      },
      () => {
        const break_locations = this.removeUuid(item_idx);
        this.updateItemFieldChanged(this.state.items[item_idx], { break_locations: JSON.stringify(break_locations) });
      }
    );
  };

  /**
   * Handles the change of a location value for a break location.
   *
   * @param {object} selectedOption - The selected option representing the new location value.
   * @param {number} item_idx - The index of the item in the items array.
   * @param {number} location_idx - The index of the break location within the item.
   * @param {object} location - The current break location object.
   * @param {string} location_key - The key of the location property to be updated.
   */
  handleLocationChange = (selectedOption, item_idx, location_idx, location, location_key) => {
    if (this.state.wireErrors.length > 0) this.setState({ wireErrors: [] });

    this.setState(
      (prevState) => {
        const items = [...prevState.items];
        location = items[item_idx].break_locations[location_idx];
        location[location_key] = selectedOption.value;
        items[item_idx].break_locations[location_idx] = location;
        return { items };
      },
      () => {
        const break_locations = this.removeUuid(item_idx);
        this.updateItemFieldChanged(this.state.items[item_idx], { break_locations: JSON.stringify(break_locations) });
      }
    );
  };

  /**
   * Returns a formatted value object for a given value.
   *
   * @param {*} value - The value to be formatted.
   * @returns {object} Returns a formatted value object.
   */
  getFormattedValue = (value) => {
    return value ? { value, label: value } : null;
  };

  /**
   * Removes a break location from an item.
   *
   * @param {number} idx - The index of the item in the items array.
   * @param {number} location_idx - The index of the break location to be removed.
   */
  removeBreakLocation = (idx, location_idx) => {
    this.setState(
      (prevState) => {
        const { items } = prevState;
        const updatedItems = [...items];
        const updatedBreakLocations = updatedItems[idx].break_locations.filter((_, index) => index !== location_idx);
        updatedItems[idx] = {
          ...updatedItems[idx],
          break_locations: updatedBreakLocations,
        };
        return { items: updatedItems };
      },
      () => {
        const break_locations = this.removeUuid(idx);
        this.updateItemFieldChanged(this.state.items[idx], { break_locations: JSON.stringify(break_locations) });
      }
    );
  };

  /**
   * Adds a new break location to an item.
   *
   * @param {number} idx - The index of the item in the items array.
   */
  addBreakLocation = (idx) => {
    this.setState(
      (prevState) => {
        const { items } = prevState;
        const updatedItems = [...items];
        updatedItems[idx] = {
          ...updatedItems[idx],
          break_locations: [...updatedItems[idx].break_locations, { uuid: uuidv4() }],
        };
        return { items: updatedItems };
      },
      () => {
        const break_locations = this.removeUuid(idx);
        this.updateItemFieldChanged(this.state.items[idx], { break_locations: JSON.stringify(break_locations) });
      }
    );
  };

  /**
   * Displays the break location selection for a wire item.
   *
   * @param {object} item - The wire item.
   * @param {number} idx - The index of the item in the items array.
   * @param {number} location_idx - The index of the break location within the item.
   * @param {object} location - The break location object.
   * @param {boolean} edit - Flag indicating whether the item is in edit mode.
   * @returns {JSX.Element} Returns the JSX element for displaying the break location selection.
   */
  displaySelectBreakLocation = (item, idx, location_idx, location, edit) => {
    const is_lower = item.ireq_details__ireq_parts__description.toLowerCase().includes('lower');
    const error = this.state.wireErrors.includes(item.id);
    if (!edit) {
      return (
        <div className="location-container">
          {`${location?.start_location}${location?.start_location_number} to ${location?.end_location}${location?.end_location_number}`}
        </div>
      );
    }
    return (
      <div className={`location-container ${error ? 'error' : ''}`}>
        <div className="start-end-location">
          <Select
            name="start-location"
            placeholder="-"
            value={this.getFormattedValue(location?.start_location)}
            options={LOCATION_OPTION[is_lower ? LOWER : UPPER]}
            onChange={(selectedOption) => this.handleLocationChange(selectedOption, idx, location_idx, location, 'start_location')}
            className="custom-select"
            menuPortalTarget={document.body}
          />
          <Select
            name="start-location_number"
            placeholder="-"
            value={this.getFormattedValue(location?.start_location_number)}
            options={LOCATION_NUMBERS}
            onChange={(selectedOption) => this.handleLocationChange(selectedOption, idx, location_idx, location, 'start_location_number')}
            className="custom-select"
            menuPortalTarget={document.body}
          />
        </div>
        <span className="bold-text">to</span>
        <div className="start-end-location">
          <Select
            name="end-location"
            placeholder="-"
            value={this.getFormattedValue(location?.end_location)}
            options={LOCATION_OPTION[is_lower ? LOWER : UPPER]}
            onChange={(selectedOption) => this.handleLocationChange(selectedOption, idx, location_idx, location, 'end_location')}
            className="custom-select"
            menuPortalTarget={document.body}
          />
          <Select
            name="end-location_number"
            placeholder="-"
            value={this.getFormattedValue(location?.end_location_number)}
            options={LOCATION_NUMBERS}
            onChange={(selectedOption) => this.handleLocationChange(selectedOption, idx, location_idx, location, 'end_location_number')}
            className="custom-select"
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    );
  };

  /**
   * Displays the wire items, including photos, course of action, break locations, and process steps.
   *
   * @param {object} item - The wire item object.
   * @param {number} idx - The index of the wire item.
   * @param {boolean} edit - Flag indicating whether the item is in edit mode.
   * @returns {JSX.Element} Returns the JSX element for displaying the wire items.
   */
  displayWireItems = (item, idx, edit, modal) => {
    const tableCellClass = modal ? 'table-cell-modal' : 'table-cell';
    const boldClass = modal ? 'bold-text-modal' : 'bold-text';
    const photos = this.props.ws_data.ir_files.filter((file) => file.file_type === 'photos' && file.ireq_wire_selection_id === item.id);

    const comment = item.comment ? JSON.parse(item.comment) : null;
    const canEditStatus = !modal && (comment?.success === 'true' ? false : edit || this.props.status === WS_COMPLETE_FABRICATION_STATUS);
    return (
      <>
        <div className="table-item-row">
          <div className={`${tableCellClass} ${boldClass}`}>
            {!edit && `${idx + 1}.`} {`${item.ireq_details__ireq_parts__description} (${this.getPrevRevWireType(item)})`}
          </div>
          <div className={`${tableCellClass} second-col`}>
            {canEditStatus ? (
              <Select
                name="fabrication_status"
                placeholder="Select..."
                value={this.getFormattedValue(item?.status)}
                options={FABRICATION_STATUSES}
                onChange={(selectedOption) => this.handleStatusChange(selectedOption, idx)}
                classNamePrefix="custom-select"
                menuPortalTarget={document.body}
              />
            ) : (
              <span className={item?.status === SUCCESS ? 'success-green' : 'failure-red'}>{item?.status}</span>
            )}
          </div>
        </div>
        {item?.status === FAILURE && (
          <>
            {photos.length > 0 || edit ? (
              <>
                <div className="table-row">
                  <div className={`${tableCellClass} ${boldClass}`}>
                    <div className="margin-left-40">Photos</div>
                  </div>
                  {edit && (
                    <div className={`${tableCellClass} second-col`}>
                      <button
                        className="transparent-button lg fit"
                        onClick={() => {
                          this.onUploadClick(item);
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  )}
                </div>
                <div className="table-row">
                  <div className="margin-left-40 ir-wfr-thumbnail-container">
                    <ThumbnailURLInterface
                      files={photos}
                      images={configLightBox(photos)}
                      theme="dark"
                      allow_remove={edit ? true : false}
                      onRemove={(image) => this.removeIRFile(image)}
                      key={this.state.refreshThumbnail}
                      fit
                      hide_occlusion={true}
                    />
                  </div>
                </div>
                <div className="line-division" />
              </>
            ) : null}
            <div className="table-row">
              <div className={`${tableCellClass} ${boldClass}`}>
                <div className="margin-left-40">Course of Action</div>
              </div>
              <div className={`${tableCellClass} second-col`}>
                {edit ? (
                  <Select
                    name="course_of_action"
                    placeholder="Select action"
                    value={this.getFormattedValue(item?.course_of_action)}
                    options={
                      item.original_smartwire.toLowerCase() === 'template' && item.ir_type === 'Original Wire'
                        ? COURSE_OF_ACTION_ONLY_REDESIGN
                        : COURSE_OF_ACTION
                    }
                    onChange={(selectedOption) => this.handleCourseOfActionChange(selectedOption, idx)}
                    classNamePrefix="custom-select"
                    menuPortalTarget={document.body}
                  />
                ) : (
                  item?.course_of_action
                )}
              </div>
            </div>
            <div className="line-division" />
            {item?.break_locations.map((location, location_idx) => (
              <React.Fragment key={location.uuid}>
                <div className="table-row">
                  <div className={`${tableCellClass} ${boldClass}`}>
                    <div className="margin-left-40">Break Location</div>
                  </div>
                  <div className={`${tableCellClass} second-col`}>{this.displaySelectBreakLocation(item, idx, location_idx, location, edit)}</div>
                </div>
                <div className="container-relative">
                  {location_idx > 0 && edit && (
                    <span className="delete-location" onClick={() => this.removeBreakLocation(idx, location_idx)}>
                      x
                    </span>
                  )}
                </div>
                <div className="table-row">
                  <div className={`${tableCellClass} ${boldClass}`}>
                    <div className="margin-left-40">Process Step</div>
                  </div>
                  <div className={`${tableCellClass} second-col`}>
                    {edit ? (
                      <Select
                        name="process-step"
                        placeholder="Select step wire failed"
                        value={this.getFormattedValue(location?.process_step)}
                        options={PROCESS_STEP}
                        onChange={(selectedOption) => this.handleProcessStepChange(selectedOption, idx, location_idx)}
                        classNamePrefix="custom-select"
                        menuPortalTarget={document.body}
                      />
                    ) : (
                      location?.process_step
                    )}
                  </div>
                </div>
                <div className="line-division" />
              </React.Fragment>
            ))}
            {edit && (
              <div className="table-row">
                <a className="margin-left-40 add-location-link" onClick={() => this.addBreakLocation(idx)}>
                  Add another break location
                  <i class="fa fa-plus-circle" aria-hidden="true" />
                </a>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  /**
   * Displays the wire items in a table format.
   *
   * @param {Array} items - The array of wire items.
   * @param {boolean} edit - Flag indicating whether the items are in edit mode.
   * @param {boolean} modal - Flag indicating whether the items are displayed in a modal.
   * @returns {JSX.Element} Returns the JSX element for displaying the wire items.
   */
  displayWires = (items, edit = true, modal = false) => {
    const tableCellClass = modal ? 'table-cell-modal' : 'table-cell';
    return (
      <div className={edit ? 'table-items-edit' : 'table-items-modal'}>
        {edit && (
          <div className="table-header">
            <div className={tableCellClass}>Wire</div>
            <div className={`${tableCellClass} second-col`}>Status</div>
          </div>
        )}
        {items.map((item, idx) => {
          return <React.Fragment key={idx}>{this.displayWireItems(item, idx, edit, modal)}</React.Fragment>;
        })}
      </div>
    );
  };

  /**
   * Returns the initiated date for wire fabrication review.
   *
   * @returns {string|null} Returns the initiated date if available, otherwise null.
   */
  getWireFabractionInitiatedDate() {
    return this.props?.ws_data?.ws?.find((item) => item.status === WS_WIRE_FABRICATION_STATUS).created_date;
  }

  /**
   * Returns the resolved date for wire fabrication review.
   *
   * @returns {string|null} Returns the resolved date if available, otherwise null.
   */
  getWireFabractionResolvedDate() {
    return this.props?.ws_data?.ws?.find((item) => item.current_ind === true).modified_date;
  }

  /**
   * Displays the resolved message for wire fabrication review.
   *
   * @returns {JSX.Element} Returns the JSX element for displaying the resolved message.
   */
  displayResolvedMessage() {
    return (
      <div className="resolve-message-box">
        <span className="bold-text">Wire Fabrication Review Initiated </span>
        {convertDate(this.getWireFabractionInitiatedDate())}
        <span className="bold-text"> Resolved </span>
        {convertDate(this.getWireFabractionResolvedDate())}
      </div>
    );
  }

  hasDuplicateLocation(bl1, bl2) {
    const sameStart = bl1.start_location === bl2.start_location && bl1.start_location_number === bl2.start_location_number;
    const sameEnd = bl1.end_location === bl2.end_location && bl1.end_location_number === bl2.end_location_number;
    return sameStart && sameEnd;
  }

  hasInverse(bl1, bl2) {
    const inverse = bl1.start_location === bl2.start_location && bl1.start_location_number === bl2.end_location_number;
    const inverse2 = bl1.end_location === bl2.end_location && bl1.end_location_number === bl2.start_location_number;
    return inverse && inverse2;
  }

  /**
   * Determines if given location has another duplicate or inverse location
   * @function
   * @param {list} all_break_locations - All break locations of the wire
   * @param {object} break_location - Given break location
   * @returns {boolean} - True or false
   */
  hasDuplicateOrInverseLocation(wire, break_location) {
    const all_break_locations = wire.break_locations;
    return all_break_locations.some((bl) => {
      if (bl.uuid !== break_location.uuid) {
        return this.hasDuplicateLocation(bl, break_location) || this.hasInverse(bl, break_location);
      }
      return false;
    });
  }

  /**
   * Determines if wires have incorrect break location input
   * @function
   * @param {list} wires - List of wires
   * @returns {boolean} - True or false
   */
  hasBreakLocationError(wires) {
    const errors = [];
    for (const wire of wires) {
      const breakLocations = wire.break_locations;
      if (breakLocations) {
        for (const bl of breakLocations) {
          const is_same_tooth = bl.start_location === bl.end_location && bl.start_location_number === bl.end_location_number;
          const has_duplicate_or_inverse_location = this.hasDuplicateOrInverseLocation(wire, bl);
          if (is_same_tooth || has_duplicate_or_inverse_location) {
            errors.push(wire.id);
          }
        }
      }
    }
    this.setState({ wireErrors: errors });
    return errors.length;
  }

  submitWireFabricationReview = (action_modal) => {
    if (!this.hasBreakLocationError(this.state.items.filter((wire) => wire.status === 'Failure'))) {
      this.setState({ action_modal });
    }
  };

  cancelWireFabrication = (action_modal) => {
    this.setState({ action_modal: action_modal });
  };

  /**
   * Generates an action button element.
   *
   * @param {string} action_modal - The action modal name.
   * @param {string} label - The label for the button.
   * @param {boolean} enabled - Flag indicating whether the button is enabled.
   * @returns {JSX.Element} Returns the JSX element for the action button.
   */
  getActionButton(action_modal, label, enabled = true) {
    const wireFabComplete = ['complete_wire_fabrication_recut', 'complete_wire_fabrication'].includes(action_modal);
    const actionFunction = wireFabComplete ? this.submitWireFabricationReview : this.cancelWireFabrication;
    return (
      <button type="button" className="btn btn-light" onClick={() => actionFunction(action_modal)} disabled={!enabled}>
        {label}
      </button>
    );
  }

  /**
   * Checks if all wire items are either successful or require recut.
   *
   * @returns {boolean} Returns true if all wire items are either successful or require recut, otherwise false.
   */
  isOnlyRecut = () => {
    const { items } = this.state;
    return items.every((item) => item.status === SUCCESS || (item.status === FAILURE && item.course_of_action === 'Recut'));
  };

  /**
   * Checks if the "Complete" button should be enabled.
   *
   * @returns {boolean} Returns true if the "Complete" button should be enabled, otherwise false.
   */
  isCompleteButtonEnabled = () => {
    const { items } = this.state;
    const hasFailure = items.some((item) => item.status === FAILURE);
    const isComplete = items
      .filter((item) => item.status === FAILURE)
      .every(
        (item) =>
          item.course_of_action &&
          item.break_locations.every(
            (location) =>
              location.process_step && location.start_location && location.start_location_number && location.end_location && location.end_location_number
          )
      );
    if (this.props.status === WS_COMPLETE_FABRICATION_STATUS) {
      return !hasFailure;
    }

    return hasFailure && isComplete;
  };

  /**
   * Displays the action buttons based on the current status.
   *
   * @returns {JSX.Element|null} Returns the JSX element for displaying the action buttons, or null if no buttons are available.
   */
  displayButtons() {
    const action = this.isOnlyRecut() ? 'complete_wire_fabrication_recut' : 'complete_wire_fabrication';
    if ([WS_WIRE_FABRICATION_STATUS, WS_WIRE_FABRICATION_REINITIATED_STATUS].includes(this.props.status)) {
      return (
        <div>
          <div className="bpp-10-padding" />
          <div className="text-center">
            {this.getActionButton(action, 'Complete', this.isCompleteButtonEnabled())}
            {this.getActionButton('cancel_wire_fabrication', 'Cancel')}
          </div>
        </div>
      );
    }

    const { items } = this.state;
    let isReinitiateEnabled = false;
    if (this.props.status === WS_COMPLETE_FABRICATION_STATUS) {
      isReinitiateEnabled = items.some((item) => item.status === FAILURE);
    }
    return (
      <div>
        <div className="bpp-10-padding" />
        <div className="text-center">
          {this.getActionButton(
            'resolve_wire_fabrication',
            'Resolved',
            this.props.status === WS_COMPLETE_FABRICATION_STATUS ? this.isCompleteButtonEnabled() : false
          )}
          {this.getActionButton('reinitiate_wire_fabrication', 'Reinitiate', isReinitiateEnabled)}
        </div>
      </div>
    );
  }

  /**
   * Performs an HTTP request to update the status action.
   *
   * @param {string} action - The action to be performed.
   */
  fetchUpdateStatusAction(action) {
    setTokenHeader();
    this.setState({ modal_in_progress: true });
    const that = this;
    Axios.post(`/apiv3/ireqautomationstatus/${this.props.ireq_id}/ws/${action}`)
      .then(function (res) {
        that.setState({
          action_modal: '',
          modal_in_progress: false,
        });
        that.props.refreshWSWorkflow();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status === 409) {
          that.setState({
            action_modal: '',
            modal_in_progress: false,
          });
        }
      });
  }

  /**
   * Handles the confirmation of the modal action.
   */
  onModalConfirm() {
    this.fetchUpdateStatusAction(this.state.action_modal);
  }

  /**
   * Displays the modal action based on the current action_modal state.
   *
   * @returns {JSX.Element|null} Returns the JSX element for displaying the modal action, or null if no modal action is available.
   */
  displayModalAction = () => {
    const { action_modal } = this.state;
    const ireq_id = removeIreqIdInitialNumber(this.props.ireq_id);
    const modals = {
      complete_wire_fabrication: {
        preset: 'decision-wire-selection',
        header_text: `Wire Fabrication Review - ${ireq_id}`,
        message_text: (
          <>
            <p>Please confirm the failed smartwires below</p>
            {this.displayWires(
              this.state.items.filter((item) => item?.status === FAILURE),
              false,
              true
            )}
          </>
        ),
        confirm_btn_text: 'Confirm',
        close_btn_text: 'Cancel',
        in_progress_text: 'Confirming...',
        className: 'wire-failure-modal-ir',
      },
      cancel_wire_fabrication: {
        preset: 'decision-wire-selection',
        header_text: `Wire Fabrication Review - ${ireq_id}`,
        message_text: (
          <>
            <p>Are you sure you want to cancel the following wire fabrication review?</p>
          </>
        ),
        confirm_btn_text: 'Confirm',
        close_btn_text: 'Cancel',
        in_progress_text: 'Confirming...',
      },
      reinitiate_wire_fabrication: {
        preset: 'decision-wire-selection',
        header_text: `Wire Fabrication Review - ${ireq_id}`,
        message_text: (
          <>
            <p>Would you like to reinitiate wire fabrication review?</p>
          </>
        ),
        confirm_btn_text: 'Reinitiate',
        close_btn_text: 'Cancel',
        in_progress_text: 'Reinitiating...',
      },
      resolve_wire_fabrication: {
        preset: 'decision-wire-selection',
        header_text: `Wire Fabrication Review - ${ireq_id}`,
        message_text: (
          <>
            <p>Are you sure you want to resolve the following wire fabrication?</p>
          </>
        ),
        confirm_btn_text: 'Resolve',
        close_btn_text: 'Cancel',
        in_progress_text: 'Resolving...',
      },
    };
    modals['complete_wire_fabrication_recut'] = modals['complete_wire_fabrication'];

    if (action_modal in modals) {
      return (
        <Modal
          onCloseButtonClick={() => this.setState({ action_modal: '' })}
          theme="bpp"
          modal_size_class="modal"
          {...modals[action_modal]}
          in_progress={this.state.modal_in_progress}
          onConfirmButtonClick={this.onModalConfirm}
        />
      );
    }
    return null;
  };

  /**
   * Fetches and updates the IR file with the provided upload data.
   *
   * @param {object} item - The wire item object.
   * @param {array} upload_data - The array of upload data.
   */
  fetchUpdateIRFile = (item, upload_data) => {
    const data = {
      photos: [],
    };

    for (let photo of upload_data) {
      data['photos'].push({
        ...photo,
        ireq_wire_selection_id: item.id,
        manual_process: true,
        file_type: 'photos',
      });
    }

    const ireq_id = this.props.ireq_id;
    const action = 'wire_fabrication_upload_photo';
    const that = this;
    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/${action}`, data)
      .then(function (res) {
        that.setState({ upload_modal: null });
        that.props.refreshWSWorkflow();
      })
      .catch(function (err) {
        that.setState({ upload_modal: null });
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Fetches and updates the IR file with the provided upload data.
   *
   * @param {object} item - The wire item object.
   * @param {array} upload_data - The array of upload data.
   */
  removeIRFile = (image) => {
    const ireq_id = this.props.ireq_id;
    const action = 'wire_fabrication_remove_photo';
    const that = this;
    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/${action}`, image)
      .then(function () {
        that.props.refreshWSWorkflow();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Handles the click event for uploading photos.
   *
   * @param {object} item - The wire item object.
   */
  onUploadClick = (item) => {
    const ireq_id = this.props.ireq_id;
    const config = {
      header_text: `Photo Upload - ${removeIreqIdInitialNumber(ireq_id)}`,
      ireq_id: ireq_id,
      preset: 'upload',
      case_id: 'this.state.selected_case_id',
      folder: 'photos',
      type: 'smartwire_photos',
      files_uploaded: this.props?.ws_data?.ir_files.filter((file) => file.file_type === 'photos' && file.ireq_wire_selection_id === item.id),
      onUpload: (data) => {
        this.fetchUpdateIRFile(item, data);
      },
      theme: 'bpp',
    };
    this.setState({
      upload_modal: config,
    });
  };

  /**
   * Displays the upload modal for uploading photos.
   *
   * @returns {JSX.Element} Returns the JSX element for the upload modal.
   */
  displayUploadModal = () => {
    return (
      <Modal
        onCloseButtonClick={() => {
          this.setState({ upload_modal: null });
        }}
        {...this.state.upload_modal}
      />
    );
  };

  /**
   * Displays the modal for displaying information or user interaction.
   *
   * @returns {JSX.Element} Returns the JSX element for the modal.
   */
  displayModal = () => {
    return (
      <Modal
        onCloseButtonClick={() => {
          this.setState({ modal: null });
        }}
        {...this.state.modal}
      />
    );
  };

  /**
   * Retrieves the wire fabrication status logs.
   *
   * @returns {array} Returns an array of wire fabrication status logs.
   */
  getWireFabricationStatusLogs = () => {
    // find the index of first Wire Fabrication to show all the logs starting in that status
    // then filter only status that will show logs
    const logs = [...this.props?.ws_data?.ws].reverse();
    const found_idx = logs.findIndex((item) => item.status === WS_WIRE_FABRICATION_STATUS);
    if (found_idx === -1) return;

    return logs.filter((item, index) => {
      if (item.status === WS_CUSTOM_WO_STATUS) {
        const smartwires = this.props.items.filter((wire) => wire.group === 'smartwire' && wire.break_locations && wire.automation_status_id === item.id);
        const isRecutOnly = smartwires.length > 0 && smartwires.every((wire) => wire.course_of_action === 'Recut');
        return isRecutOnly;
      }
      return index >= found_idx ? WIRE_FABRICATION_LOG_STATUSES.hasOwnProperty(item.status) : false;
    });
  };

  /**
   * Displays the wire fabrication status logs.
   */
  displayLogs = () => {
    const ireq_id = removeIreqIdInitialNumber(this.props.ireq_id);
    const status_log = this.getWireFabricationStatusLogs();
    let smartwires = _.orderBy(
      this.props.ws_data.ws_details.filter((item) => item.group === 'smartwire'),
      'wire_rev'
    );

    for (const smartwire of smartwires) {
      if (smartwire.course_of_action && smartwire.break_locations) smartwire.status = 'Failure';
    }

    const items = smartwires
      .filter((item) => item.status === 'Failure')
      .map((item) => {
        return this.updateItem(item);
      });

    const body = status_log.map((status) => {
      return (
        <div className="align-left">
          <span className="bold-text">{convertDateWithFormat(status.modified_date, 'MM/DD/YY hh:mm A')}</span>{' '}
          {status.status === WS_CUSTOM_WO_STATUS ? 'Wire Fabrication Review Completed' : WIRE_FABRICATION_LOG_STATUSES[status.status]}
          {status.rework && ` (Rework ${status.rework})`}
          {[WS_REDESIGN_FABRICATION_STATUS, WS_COMPLETE_FABRICATION_STATUS, WS_CUSTOM_WO_STATUS].includes(status.status) && (
            <div className="log-wire-container">
              {this.displayWires(
                items.filter((item) => item.automation_status_id === status.id),
                false,
                true
              )}
            </div>
          )}
        </div>
      );
    });

    const config = {
      header_text: `Wire Fabrication Review Log - ${ireq_id}`,
      message_text: <>{body}</>,
      close_btn_text: 'Close',
      theme: 'bpp',
      modal_size_class: 'modal-dialog fabrication-log-modal',
    };
    this.setState({ modal: config });
  };

  getPrevRevWireType(item) {
    const prevWire = this.state.prevRevWires.find((prevWire) => item.original_wire_name === prevWire.original_wire_name);
    if (prevWire) {
      return prevWire.wire_type ? prevWire.wire_type : prevWire.original_smartwire;
    }
    return item.wire_type ? item.wire_type : item.original_smartwire;
  }

  render() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const permitted = userHasPermission('IREQ_WIRE_FABRICATION_REVIEW', user_roles_and_permissions.permissions);
          return (
            <>
              <div className="bold-text business-dark-heading log-header">
                Wire Fabrication Review
                <i className="fa fa-file-text-o document-icon" aria-hidden="true" onClick={this.displayLogs} />
              </div>
              <div className="business-dark-theme">
                {permitted ? (
                  <div className="bpp-cozy-text-headlines">
                    {this.props.status === WS_WIRE_FABRICATION_RESOLVED_STATUS ? (
                      this.displayResolvedMessage()
                    ) : (
                      <>
                        {this.displayWires(this.state.items, EDIT_STATUSES.includes(this.props.status))}
                        {this.displayButtons()}
                      </>
                    )}
                  </div>
                ) : (
                  <div>Wire Fabrication Review in Progress...</div>
                )}
                {this.state.action_modal && this.displayModalAction()}
                {/* {this.displayFileUploadError()} */}
                {this.state.upload_modal && this.displayUploadModal()}
                {this.state.modal && this.displayModal()}
              </div>
              {this.state.refresh && <SessionExpire />}
            </>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}

export default FabricationWorkflow;
